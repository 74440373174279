<template>
  <b-overlay :show="loading">
    <template>
      <div>
        <b-row>
          <b-col
              cols="12"
              xl="6"
              md="6"
          >
            <Update />
          </b-col>

          <b-col>
            <MFA />
          </b-col>
        </b-row>
      </div>
    </template>
  </b-overlay>
</template>
<script>
import { get, cloneDeep } from "lodash";
import { required, email } from '@validations'
import { BCol, BRow, BOverlay } from "bootstrap-vue";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import MFA from "@/pages/auth/profile/mfa.vue";
import Update from "@/pages/auth/profile/update.vue";

export default {
  components: {
    MFA,
    BRow,
    BCol,
    Update,
    BOverlay,
  },
  directives: {},
  data(){
    return {
      loading: false,
      updatedImage: null,
      userData: null,
      deactivationToken: '',
      code: '',
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      active_status: false,
      required,
      email
    }
  },
  computed: {
    mfa_conf() {
      return this.getValueFromSource(this.currentUser, "mfa_conf", null)
    },
    avatarPath() {
      return this.getValueFromSource(this.currentUser, 'avatar.path', '');
    }
  },
  created(){
    this.userData = cloneDeep(this.currentUser)
  },
  methods: {
    async onUpdateUser(){
      try {
        this.loading = true;

        const formData = new FormData();

        formData.append('date_of_birth', this.getValueFromSource(this.userData, 'date_of_birth', ''));
        formData.append('residential_address', this.getValueFromSource(this.userData, 'residential_address', ''));

        if (this.updatedImage){
          formData.append("avatar", this.updatedImage);
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const udpated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, udpated_user);

        if (this.updatedImage){
          this.updatedImage = null;
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async requestMfaDeactivation() {
      // this.loading = true;
      try {
        const response = await this.useJwt()
            .mfaService
            .deactivateMfaRequest();
        const {
          mfa_deactivation_token
        } = response.data.data;
        this.deactivationToken = mfa_deactivation_token;
        await this.useJwt().mfaService.setMfaDeactivationToken(this.deactivationToken);
        this.$refs['otp-verification'].toggle('#toggle-btn')
      } catch (error) {
        console.log(error)
      } finally {
        // this.loading = true;
      }
    },
    async deactivateMfa() {
      this.loading = true;
      try {
        const payload = {
          token: this.code
        }
        const response = await this.useJwt()
            .mfaService
            .deactivateMfa(payload);

        await this.useJwt().mfaService.clearMfaDeactivationToken(this.deactivationToken);

        const { message } = response.data.data;
        this.$refs['otp-verification'].toggle('#toggle-btn')


        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mfa Successfully Deactivated',
            icon: 'EditIcon',
            variant: 'success',
            text: message
          },
        });

        this.mfa_conf = null;
        this.active_status = false;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
$neutral : #80A0AF;
$main-color: #011151;

.exxtra-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    font-weight: bold;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }

  }
}

</style>
